export type Funcionality = {
  id: string;
  name: string;
};

export type RoleFunctionality = {
  roleId: string;
  functionalityId: string;
  accessLevel: AccessLevel;
  functionality: Funcionality;
};

export type FunctionalityKeyValue = Record<FunctionalityName, string>;

export enum AccessLevel {
  READ = 1,
  UPDATE = 2,
  CREATE = 3,
  DELETE = 4,
}

export enum FunctionalityName {
  GRANT_PORTAL_ACCESS = 'GRANT_PORTAL_ACCESS',
  REMOVE_PORTAL_ACCESS = 'REMOVE_PORTAL_ACCESS',
  DEFINE_USER_ROLES = 'DEFINE_USER_ROLES',
  DOWNLOAD_ADMIN_REPORTS = 'DOWNLOAD_ADMIN_REPORTS',
  LIST_ALL_USERS = 'LIST_ALL_USERS',
  GRANT_VIEWING_PROMO_ACCESS = 'GRANT_VIEWING_PROMO_ACCESS',
  REMOVE_VIEWING_PROMO_ACCESS = 'REMOVE_VIEWING_PROMO_ACCESS',
  TRIGGER_PORTAL_UPDATE = 'TRIGGER_PORTAL_UPDATE',
  DOWNLOAD_MANAGER_REPORTS = 'DOWNLOAD_MANAGER_REPORTS',
  UPDATE_PROMOTION_DATA = 'UPDATE_PROMOTION_DATA',
  UPDATE_PRIZES_DATA = 'UPDATE_PRIZES_DATA',
  ACCEPT_WINNERS = 'ACCEPT_WINNERS',
  PRE_APPROVE_WINNERS = 'PRE_APPROVE_WINNERS',
  REJECT_WINNER = 'REJECT_WINNER',
  REQUEST_ALTERNATIVE_WINNERS = 'REQUEST_ALTERNATIVE_WINNERS',
  DOWNLOAD_AUDIT_SCREEN_REPORT = 'DOWNLOAD_AUDIT_SCREEN_REPORT',
  VIEW_LIMITED_INFO_AT_AUDIT_SCREEN = 'VIEW_LIMITED_INFO_AT_AUDIT_SCREEN',
  VIEW_ALL_PROMOS = 'VIEW_ALL_PROMOS',
  VIEW_ALL_PRIZES = 'VIEW_ALL_PRIZES',
  VIEW_PROMOS_WITH_GRANTED_ACCESS = 'VIEW_PROMOS_WITH_GRANTED_ACCESS',
  VIEW_LAST_UPDATE_INFORMATION = 'VIEW_LAST_UPDATE_INFORMATION',
  DOWNLOAD_AUDIT_SCREEN_FULL_PARTICIPATIONS_REPORT = 'DOWNLOAD_AUDIT_SCREEN_FULL_PARTICIPATIONS_REPORT',
  DOWNLOAD_PROMOTION_REGULATION = 'DOWNLOAD_PROMOTION_REGULATION',
  VIEW_FULL_PARTICIPANT = 'VIEW_FULL_PARTICIPANT',
  VIEW_PROMOTION_PARTICIPANT = 'VIEW_PROMOTION_PARTICIPANT',
  DOWNLOAD_AUDIT_SCREEN_END_OF_PROMOTION_REPORT = 'DOWNLOAD_AUDIT_SCREEN_END_OF_PROMOTION_REPORT',
  VIEW_REPORTS_DASHBOARD = 'VIEW_REPORTS_DASHBOARD',
  VIEW_MANAGEMENT_DASHBOARD = 'VIEW_MANAGEMENT_DASHBOARD',
  PROMOTION_NOTE = 'PROMOTION_NOTE'
}
